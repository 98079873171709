/* eslint-disable no-unused-vars */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { get as getCookie } from "js-cookie"
import React, { useEffect, useState } from "react"

import brandTheme from "../theme"
import CtaLinks from "../../utils/CtaLinks"
import { Res } from "../../utils/constants"
import { COOKIE } from "../../helpers/constants"
import { useScreenSize } from "../../utils/hooks"
import {
  getImageFluid,
  getLanguage,
  getLogos,
  getMarkdownHtml,
  setNetreferCookies,
  getPageSettings,
  getImageFileUrl,
  getRawMarkdown
} from "../../helpers"

import LeoVegasLogo from "../../assets/leovegas-casino-logo.png"

import Terms from "../../components/Terms"
import Footer from "../../components/Footer"
import LUAwards from "../../components/LUAwards"
import LUBannerNew from "../../components/LUBannerNew"
import Layout from "../../components/Layout/index"
import FooterLinks from "../../components/FooterLinks"
import LUThreeSteps from "../../components/LUThreeSteps"
import LUSocialProof from "../../components/LUSocialProof"
import LUCookieConsent from "../../components/LUCookieConsent"
import LUNavigationBar from "../../components/LUNavigationBar"
import LUBannerflowBanner from "../../components/LUBannerflowBanner"
import LUPaymentProviderBar from "../../components/LUPaymentProviderBar"
import LUSpinningWheelBanner from "../../components/LUSpinningWheelBanner"

const LeoUniverseTemplate = ({ data: { contentfulLeoUniverse: data, contentfulGenericContent: genericData }, location }) => {
  const [showCookieConsent, setShowCookieConsent] = useState(false)
  const [newRedirectUrl, setNewRedirectUrl] = useState("")
  const [newLoginUrl, setNewLoginUrl] = useState(null)
  const [customCtaLink, setCustomCtaLink] = useState(null)
  const [newMiscLinks, setNewMiscLinks] = useState("")
  const [newUrlParams, setNewUrlParams] = useState("")
  const [hasPageLoaded, setHasPageLoaded] = useState(false)

  useEffect(() => {
    setHasPageLoaded(true)
  }, [])

  useEffect(() => {
    setShowCookieConsent(!getCookie(COOKIE.isCookiePolicyAccepted))

    const urlLanguage = getLanguage(data.slug)

    const contentLanguage = genericData.language
    const ctaLinks = CtaLinks(
      data.bonusProgramId,
      data.defaultMarketingSourceId,
      data.defaultMediaId,
      genericData.iosAppId,
      genericData.downloadAppAndroidLinkType?.downloadAppAndroidLinkType,
      data.pageType,
      genericData.signupUrl,
      genericData.loginUrl,
      urlLanguage,
      contentLanguage,
      null,
      genericData.gameRulesLink
    )

    setNetreferCookies({
      params: ctaLinks.urlParams,
      language: genericData.language,
      pageType: data.pageType
    })

    const { getCtaLink, miscLinks, redirectUrl, urlParams, loginUrl } = ctaLinks

    setNewRedirectUrl(redirectUrl)
    setNewLoginUrl(loginUrl)
    setNewMiscLinks(miscLinks)
    setNewUrlParams(urlParams)

    if (data.mainCtaLink) {
      setCustomCtaLink(getCtaLink(data.mainCtaLink))
    }
  }, [])

  const { width, height } = useScreenSize()

  const screenSizeReady = width.value !== 0

  const isMobile = !!width.lessThan(Res._512) && !!width.lessThan(height.value)

  const contentLanguage = genericData.language

  const {
    headerLoginCta,
    pageType,
    mainCta,
    seoTitle,
    secondaryLogo,
    bannerMainMedia,
    mobileSpinningWheelBg,
    mobileBannerMedia,
    bannerRibbonText,
    preSpinH1,
    bannerOfferMarkdown,
    offerMarkdown,
    legalLine,
    threeSteps,
    bannerBadge,
    bannerflowMobileBanner,
    bannerflowDesktopBanner,
    pageSettings,
    contentfulId,
    updatedAt,
    terms
  } = data

  const {
    language,
    logo,
    sportsbookLogo,
    pagesSettings,
    paymentsLogos,
    paymentProvidersLogos,
    ribbonText,
    threeStepsContent,
    sponsorsText,
    sponsorsLogos,
    licensesLogos,
    footerDisclaimer,
    singleRgLogo,
    responsibleGamingFooterLogos,
    affiliatesLink,
    aboutLeoVegasLink,
    responsibleGamingLink,
    gameRulesLink,
    termsLink,
    cookiePolicyLink,
    privacyPolicyLink,
    cookieConsentContent,
    cookiePolicy,
    mainCtaText,
    mainCtaIcon,
    spinningWheelSpinText,
    awardsSectionTitleLeoUniverse,
    awardsImagesLeoUniverse,
    socialProofCaptions,
    jackpotTickerImage,
    jackpotTickerImageMobile,
    jackpotFeedUrl
  } = genericData

  const ctaData = {
    pageType,
    ctaText: mainCtaText || mainCta, // Generic content CTA text will override the single page one
    newRedirectUrl: customCtaLink || newRedirectUrl
  }

  const signUpLinksDisabled = !mainCta

  const socialCaptions = socialProofCaptions[pageType.replace(" ", "_")]

  const footerLinks = [
    { title: affiliatesLink, url: "https://www.leovegasaffiliates.com/" },
    { title: aboutLeoVegasLink, url: newMiscLinks?.aboutLeoVegas },
    { title: responsibleGamingLink, url: newMiscLinks?.responsibleGaming },
    { title: newMiscLinks?.gameRules?.title, url: newMiscLinks?.gameRules?.url },
    { title: termsLink, url: newMiscLinks?.terms },
    { title: cookiePolicyLink, url: newMiscLinks?.cookiePolicy },
    { title: privacyPolicyLink, url: newMiscLinks?.privacyPolicy }
  ]

  const bannerImage = getImageFluid(isMobile ? mobileBannerMedia || mobileSpinningWheelBg : bannerMainMedia)

  const handleCookieAccept = () => setShowCookieConsent(false)

  const boxLayout = {
    margin: {
      raw: 16,
      value: "16px"
    }
  }

  const paymentProviderImages = paymentsLogos && paymentsLogos.filter(x => !(pageType !== "sportsbook" && x.title === "PayPal" && language !== "it"))

  const { isBlackText, hasJackpot, hasSpinningWheel, hidePaymentLogos, showPaymentLogosOutsideBanner, isOrangeBgLayout, orangeHighlight } =
    getPageSettings(pageSettings)

  const stepsContent = threeSteps || threeStepsContent?.[pageType.replace(" ", "_")] || threeStepsContent?.casino

  // settings based on the country applied to all pages
  const { showCollapsibleTerms, showSocialProof, showAwardsSection } = getPageSettings(pagesSettings)

  const theme = {
    ...brandTheme,
    isMobile,
    boxLayout,
    language,
    isBlackText
  }

  // const isStepsSectionVisible = stepOne && stepTwo && stepThree
  const isStepsSectionVisible = stepsContent?.stepOne && stepsContent?.stepTwo && stepsContent?.stepThree

  const isAwardsSectionVisible = showAwardsSection && awardsSectionTitleLeoUniverse && awardsImagesLeoUniverse

  const backgroundColour = "#ededed"

  const isCanada = language === "ca" || language === "ca-on" || language === "fr"
  const isSweden = language === "se"

  const mainLogo = pageType === "sportsbook" ? sportsbookLogo : logo

  const isNewMobileTemplate = isMobile && !!mobileBannerMedia

  let bannerComponent

  if (!screenSizeReady) return null

  if (hasSpinningWheel) {
    bannerComponent = (
      <LUSpinningWheelBanner
        fluidImage={bannerImage}
        preSpinTitle={preSpinH1}
        offer={bannerOfferMarkdown} // temporary. to allow the migration to Banner Offer Markdown field
        legalLine={getMarkdownHtml(legalLine)}
        ctaText={mainCtaText || mainCta}
        ctaUrl={ctaData.newRedirectUrl}
        ctaShouldPulsate={!isSweden}
        ctaIconUrl={getImageFileUrl(mainCtaIcon)}
        spinButtonText={spinningWheelSpinText}
        uniqueId={contentfulId + updatedAt}
        paymentLogos={!showPaymentLogosOutsideBanner && paymentProvidersLogos}
        isCanada={isCanada}
      />
    )
  } else {
    bannerComponent = (
      <LUBannerNew
        fluidImage={bannerImage}
        markdown={bannerOfferMarkdown}
        legalLine={getMarkdownHtml(legalLine)}
        ctaText={mainCtaText || mainCta}
        ctaUrl={signUpLinksDisabled ? null : ctaData.newRedirectUrl}
        ctaShouldPulsate={!isSweden}
        ctaIconUrl={getImageFileUrl(mainCtaIcon)}
        paymentLogos={!showPaymentLogosOutsideBanner && paymentProvidersLogos}
        ribbonText={bannerRibbonText || getMarkdownHtml(ribbonText)} // take ribbon text from single page otherwise from the generic content
        bannerBadge={bannerBadge?.file?.url}
        pageType={pageType}
        isCanada={isCanada}
        isMobile={isMobile}
        urlParams={newUrlParams}
        isOrangeBgLayout={isOrangeBgLayout}
        newOfferMarkdown={offerMarkdown}
        jackpotTickerImage={jackpotTickerImage?.file?.url}
        jackpotTickerImageMobile={jackpotTickerImageMobile?.file?.url}
        hasJackpot={hasJackpot}
        jackpotFeedUrl={jackpotFeedUrl}
        hasPageLoaded={hasPageLoaded}
        signUpLinksDisabled={signUpLinksDisabled}
        orangeHighlight={orangeHighlight}
      />
    )
  }

  return (
    <Layout
      location={location}
      title={seoTitle}
      language={language}
      backgroundColour={backgroundColour}
      theme={theme}
      isOrangeBgLayout={isOrangeBgLayout}
    >
      <LUNavigationBar
        scrollBgColour={`linear-gradient(90deg, ${brandTheme.colours.tertiary}, ${brandTheme.colours.primary})`}
        logoUrl={mainLogo?.file?.url || LeoVegasLogo}
        secondaryLogoUrl={secondaryLogo?.file?.url}
        ctaUrl={signUpLinksDisabled ? null : newLoginUrl}
        isOntario={language === "ca-on"}
        {...(headerLoginCta && { ctaText: headerLoginCta })}
        isNewMobileTemplate={isNewMobileTemplate}
        isOrangeBgLayout={isOrangeBgLayout}
      />
      <main>
        {bannerComponent}
        {bannerflowMobileBanner && bannerflowDesktopBanner && (
          <LUBannerflowBanner
            bannerflowDesktopBanner={bannerflowDesktopBanner}
            bannerflowMobileBanner={bannerflowMobileBanner}
            redirectUrl={signUpLinksDisabled ? null : newRedirectUrl}
          />
        )}
        {isStepsSectionVisible && (
          <LUThreeSteps
            stepContent={stepsContent}
            pageType={pageType}
            ctaText={mainCta}
            showCta
            overlap={!bannerflowMobileBanner && !bannerflowDesktopBanner}
            redirectUrl={signUpLinksDisabled ? null : newRedirectUrl}
            arePaymentsDisplayedInBanner={!hidePaymentLogos && !showPaymentLogosOutsideBanner && (!hasSpinningWheel || hasSpinningWheel)}
            backgroundColour={backgroundColour}
          />
        )}
        {showSocialProof && socialCaptions && <LUSocialProof captions={socialCaptions} />}

        {!hidePaymentLogos && paymentProviderImages && showPaymentLogosOutsideBanner && (
          <LUPaymentProviderBar
            images={paymentProviderImages}
            url={signUpLinksDisabled ? null : newRedirectUrl}
            isStepsSectionVisible={isStepsSectionVisible}
          />
        )}
        {isAwardsSectionVisible && <LUAwards title={awardsSectionTitleLeoUniverse} awardImages={awardsImagesLeoUniverse} />}
        <Terms
          data={getMarkdownHtml(terms)}
          urlParams={newUrlParams}
          titleColour={brandTheme.colours.primary}
          showCollapsibleTerms={showCollapsibleTerms}
          backgroundColour="#fff"
        />
        {showCookieConsent && (
          <LUCookieConsent
            disclaimerTitle={cookieConsentContent.disclaimerTitle}
            disclaimer={cookieConsentContent.disclaimer}
            cookiePolicyLink={cookieConsentContent.cookiePolicyLink}
            acceptButtonText={cookieConsentContent.buttons.accept}
            allowAllButtonText={cookieConsentContent.buttons.allowAll}
            confirmButtonText={cookieConsentContent.buttons.confirm}
            manageButtonText={cookieConsentContent.buttons.manage}
            necessary={cookieConsentContent.necessary}
            functional={cookieConsentContent.functional}
            analytics={cookieConsentContent.analytics}
            marketing={cookieConsentContent.marketing}
            cookiePolicy={getRawMarkdown(cookiePolicy)}
          />
        )}
      </main>
      <FooterLinks links={footerLinks} backgroundColour="#3e3e3e" />
      <Footer
        language={contentLanguage}
        sponsorsText={sponsorsText}
        sponsorsLogos={getLogos(sponsorsLogos)}
        licensesLogos={getLogos(licensesLogos)}
        footerDisclaimer={footerDisclaimer}
        singleRgLogo={singleRgLogo}
        responsibleGamingLogos={getLogos(responsibleGamingFooterLogos)}
        background="#2b2d32"
      />
    </Layout>
  )
}

LeoUniverseTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired
}

export default LeoUniverseTemplate

export const pageQuery = graphql`
  query LeoUniverseQuery($slug: String!, $language: String) {
    contentfulLeoUniverse(slug: { eq: $slug }) {
      pageName
      pageType
      slug
      pageSettings
      defaultMarketingSourceId
      defaultMediaId
      bonusProgramId
      headerLoginCta
      secondaryLogo {
        file {
          url
        }
      }
      bannerMainMedia {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      mobileSpinningWheelBg: mobileMainMedia {
        fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      mobileBannerMedia {
        fluid(maxWidth: 768) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      threeSteps {
        stepOne
        stepTwo
        stepThree
      }
      bannerRibbonText
      seoTitle
      bannerOfferMarkdown {
        childMarkdownRemark {
          rawMarkdownBody
          html
        }
      }
      offerMarkdown {
        childMarkdownRemark {
          rawMarkdownBody
          html
        }
      }
      preSpinH1 {
        childMarkdownRemark {
          rawMarkdownBody
          html
        }
      }
      legalLine {
        childMarkdownRemark {
          html
        }
      }
      bannerV2Colour
      mainCta
      mainCtaLink
      bannerBadge {
        file {
          url
        }
      }
      bannerflowDesktopBanner
      bannerflowMobileBanner
      terms {
        childMarkdownRemark {
          html
        }
      }
      contentfulId: contentful_id
      updatedAt
    }
    contentfulGenericContent(language: { eq: $language }) {
      language
      logo {
        file {
          url
        }
      }
      sportsbookLogo {
        file {
          url
        }
      }
      signupUrl
      loginUrl
      pagesSettings
      paymentsLogos {
        file {
          fileName
        }
        title
        description
        fixed(height: 32) {
          ...GatsbyContentfulFixed_withWebp_noBase64
          width
          height
        }
      }
      jackpotTickerImage {
        file {
          url
        }
      }
      jackpotTickerImageMobile {
        file {
          url
        }
      }
      jackpotFeedUrl
      paymentProvidersLogos {
        file {
          fileName
        }
        fixed(height: 42) {
          ...GatsbyContentfulFixed_withWebp_noBase64
          width
          height
        }
      }
      ribbonText {
        childMarkdownRemark {
          rawMarkdownBody
          html
        }
      }
      threeStepsContent {
        casino {
          stepOne
          stepTwo
          stepThree
        }
        live_casino {
          stepOne
          stepTwo
          stepThree
        }
        sportsbook {
          stepOne
          stepTwo
          stepThree
        }
      }
      affiliatesLink
      aboutLeoVegasLink
      termsLink
      gameRulesLink
      responsibleGamingLink
      cookiePolicyLink
      privacyPolicyLink
      contactLink
      sponsorsText
      sponsorsLogos {
        file {
          url
        }
        title
        description
      }
      licensesLogos {
        file {
          url
        }
        title
        description
      }
      footerDisclaimer {
        childMarkdownRemark {
          html
        }
      }
      singleRgLogo {
        file {
          url
        }
        title
        description
      }
      responsibleGamingFooterLogos {
        file {
          url
        }
        title
        description
      }
      mainCtaText
      mainCtaIcon {
        file {
          url
        }
      }
      spinningWheelSpinText
      awardsSectionTitleLeoUniverse
      awardsImagesLeoUniverse {
        title
        description
        file {
          url
          fileName
          contentType
        }
        fixed(height: 140, quality: 100) {
          ...GatsbyContentfulFixed_withWebp_noBase64
          width
          height
        }
      }
      socialProofCaptions {
        casino
        live_casino
        sportsbook
        bingo
        esports
      }
      cookieConsentContent {
        buttons {
          accept
          manage
          confirm
          allowAll
        }
        necessary {
          title
          description
        }
        functional {
          title
          description
        }
        analytics {
          title
          description
        }
        marketing {
          title
          description
        }
        disclaimer
        disclaimerTitle
        cookiePolicyLink
      }
      cookiePolicy {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
  }
`
